<script>
import ResourceList from '@shell/components/ResourceList';

export default {
  name:       'ClusterResourcedList',
  components: { ResourceList },
};
</script>

<template>
  <ResourceList />
</template>
